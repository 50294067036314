import * as React from "react";
import { Header, Container, Segment, Icon } from "semantic-ui-react";
import {withLayout} from "../components/Layout";

const PublicationsPage = () => {
  return (
    <Container>
      <Segment vertical>
        <Header as="h2">
          <Icon name="newspaper outline" />
          <Header.Content>
            Publications
          </Header.Content>
        </Header>
      </Segment>
      <Segment vertical>
        <ul>
          <li className="faq"><a href={"http://ieeexplore.ieee.org/xpl/freeabs_all.jsp?arnumber=5982136"}>Stackless Preemptive Multithreading for TinyOS</a>. William P. McCartney and Nigamanth Sridhar. Proceedings of the 7th IEEE International Conference on Distributed Computing in Sensor Systems (DCOSS'11). June 27-29. Barcelona, Spain.
          </li>
          <li className="faq">McCartney, William, <a href={"http://rave.ohiolink.edu/etdc/view?acc_num=csu1305319887"}>"Simplifying Embedded System Development through Whole-Program Compilers"</a> Doctor of Engineering, Cleveland State University, Fenn College of Engineering, 2011
          </li>
        </ul>
      </Segment>
    </Container>
  );
};

export default withLayout(PublicationsPage);
